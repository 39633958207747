import React from "react";

function Music(props) {

    var apple_music_single_items = ["https://embed.music.apple.com/us/album/blind-love/1583454546?i=1583454547"];
    var apple_music_album_items = ["https://embed.music.apple.com/us/album/backyard-mysteries-vol-1/1610666581",
    "https://embed.music.apple.com/us/album/backyard-mysteries-vol-2-ep/1632057833",
    "https://embed.music.apple.com/us/album/timeless-single/1675968060"];
    var spotify_music_items = []
    var music = []

    music.push(apple_music_single_items.map((m, i) => {
        const title = `applemusic${i}`
        return (
            <iframe allow="autoplay *; encrypted-media *;"
                    key={title}
                    title={title}
                    frameBorder="0"
                    height="150"
                    style={{
                        width: "100%",
                        maxWidth: "660px",
                        overflow: "hidden",
                        background: "transparent"
                    }}
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                    className="music"
                    src={m}>
            </iframe>
        )
    }))
    // apple music album
    music.push(apple_music_album_items.map((m, i) => {
            return (<iframe allow="autoplay *; encrypted-media *; fullscreen *"
                            key="applealbum"
                            frameBorder="0"
                            height="450"
                            style={{
                                width: "100%",
                                maxWidth: "660px",
                                overflow: "hidden",
                                background: "transparent"
                            }}
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                            className="music"
                            src={m}>
            </iframe>);
        })
    );

    music.push(spotify_music_items.map((m, i) => {
        const title = `spotifymusic${i}`
        return (
            <iframe
                key={title}
                title={title}
                src={m}
                width="300"
                height="380"
                frameBorder="0"
                allowtransparency="true"
                className="music"
                allow="encrypted-media">
            </iframe>
        )
    }))

    return (
        <div className="music-container">
            {music}
        </div>
    );
}

export default Music;
