import React, { useState, useEffect, useRef } from "react";
import { Route, NavLink, useLocation, useNavigate, Routes } from "react-router-dom";
import "./App.css";
import Menu from "./components/menu";
import Home from "./components/home";
import Music from "./components/music";
import Biography from "./components/biography";
import Videos from "./components/videos";
import Blog from "./components/blog";
import Shop from "./components/shop";
import SocialIcons from "./components/SocialIcons";
import logo from "./images/dot_o_logo_all_white.png"
import { fetchBioWords, fetchFeatureArtist, fetchHomeVideo, fetchLatestShow, fetchPosts, fetchShopItem, fetchShowLink, fetchVideoTabItems } from "./prismic-api";

function App() {

  const [bioWords, setBioWords] = useState(null);
  const [posts, setPosts] = useState(null);
  const [latestShow, setLatestShow] = useState(null);
  const [showFlag, setShowFlag] = useState(false);
  const [featuredArtist, setFeaturedArtist] = useState(null);
  const [shopItems, setShopItems] = useState(null);
  const [homeVideo, setHomeVideo] = useState(null);
  const [videoTabItems, setVideoTabItems] = useState(null);
  const [showLink, setShowLink] = useState(null);
  const showMoreContainerRef = useRef(null);
  const location = useLocation();

  // reset show flag when location changes
  useEffect(() => {
    setShowFlag(false);
  }, [location]);

  // fetch data from prismic upon page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const bioWordsData = await fetchBioWords();
        setBioWords(bioWordsData);

        const postsData = await fetchPosts();
        setPosts(postsData);

        const latestShowData = await fetchLatestShow();
        setLatestShow(latestShowData);

        const shopItemsData = await fetchShopItem();
        setShopItems(shopItemsData);

        const featureArtistData = await fetchFeatureArtist();
        setFeaturedArtist(featureArtistData)

        const homeVideoData = await fetchHomeVideo();
        setHomeVideo(homeVideoData);

        const videoTabItemsData = await fetchVideoTabItems();
        setVideoTabItems(videoTabItemsData);

        const showLinkData = await fetchShowLink();
        if (showLinkData[0]) {
          setShowLink(showLinkData[0].data.link.url);
        }
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        // Handle errors as appropriate
      }
    };

    fetchData();
  }, []);

  const show_more = () => {
    let containerHeight = showMoreContainerRef.current.offsetHeight
    let containerChildrenHeight = 0
    let children = showMoreContainerRef.current.children

    Array.from(children).forEach(element => {
      containerChildrenHeight += element.offsetHeight
      console.log(element.offsetHeight)
    });

    if (!showFlag && containerChildrenHeight > containerHeight) {
      setShowFlag(true);
    }
    else {
      setShowFlag(false);
    }
  }

  return <div className="container h-100">
    <div id="logo_section" className="row">
      <div className="row" style={{ justifyContent: "center", display: "relative" }}>
        <NavLink className="logo" to="/">
          <img src={logo} className="img-fluid center-block" alt="dot_o" />
        </NavLink>
      </div>
      <div id="tagline"
        className="row">
        <span>Drawing Out the Oneness</span>
      </div>
      <div className="row wole-menu" >
        <Menu />
      </div>
    </div>
    <div className="row h-100">
      <div id="content" className="row">
        <div id="show-more-container" ref={showMoreContainerRef} className={showFlag ? "show-content-more" : "show-content-less"}>
          <Routes>
            <Route path="/"
              element={<Home
                show_img={latestShow}
                artist={featuredArtist}
                home_video={homeVideo}
                show_link={showLink} />} />
            <Route path="/biography" element={<Biography words={bioWords} />} />
            <Route path="/music" element={<Music />} />
            <Route path="/videos" element={<Videos video_items={videoTabItems} />} />
            <Route path="/blog" element={<Blog posts={posts} />} />
            <Route path="/shop" element={<Shop shop_items={shopItems} />}>
            </Route>
          </Routes>
        </div>
        <div id="show-more" onClick={show_more}>
          <span className={showFlag ? "caret up" : "caret down"}></span>
          <span className="show-more-words">{showFlag ? "Show Less" : "Show More"}</span>
        </div>
        <div id="footer">
          <div style={{ textAlign: "center" }}>Join the DOT O email list</div>
          <a className="btn btn-light" href="https://officialdoto.us17.list-manage.com/subscribe/post?u=dce8d5092fa340e293e4da586&amp;id=84a7cd2415"
            target="_blank" rel="noopener noreferrer">SIGN ME UP</a>
          <SocialIcons />
        </div>
      </div>
    </div>
  </div>;

}

export default App;
