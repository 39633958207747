import * as prismic from "@prismicio/client";

const apiEndpoint = 'https://wole-app.prismic.io/api/v2';

// Create a client
const client = prismic.createClient("wole-app");

export const fetchBioWords = async () => {
    const response = await client.getAllByType('bio_wo');
    return response[0];
};


export const fetchPosts = async () => {
    const response = await client.getAllByType('post');
    return response;
};

export const fetchLatestShow = async () => {
    const response = await client.getAllByType('latest_show');
    return response;
};

export const fetchShopItem = async () => {
    const response = await client.getAllByType('shop_item');
    return response;
};

export const fetchFeatureArtist = async () => {
    const response = await client.getAllByType('featured_artist');
    return response;
};

export const fetchHomeVideo = async () => {
    const response = await client.getAllByType('home_video');
    return response;
};

export const fetchVideoTabItems = async () => {
    const response = await client.getAllByType('videos_tab_item');
    return response;
};

export const fetchShowLink = async () => {
    const response = await client.getAllByType('show_link');
    return response;
};