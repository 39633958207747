import React from "react";
import {RichText} from "prismic-reactjs";
import pic from "../images/hands.PNG";

function Biography(props) {
    if (props.words) {
        const document = props.words.data;
        return (
            <div>
                <div class="text-center"><img className="img-fluid" src={pic} alt="wole"/></div>
                <div className="words">{RichText.render(document.text)}</div>
            </div>
        );
    }
    return <p>Loading Bio....</p>;
}

export default Biography;
