import React from "react";
import shopImage from "../images/bandcamp-button-bc-circle-grey-128.png"

function Shop(props) {
    return (<div className="row" style={{margin: "10px", flexDirection: "column", alignContent: "center"}}>
        <div style={{
            display: "inline-block",
            position: "relative",
            margin: "auto",
            width: "70%",
        }}
        >
            <div className="text-center">
                <span className="font-weight-bold">CLICK HERE {">"}</span>
                <a className="mt-2 ml-2" href="https://bethyselfmusicproduction.bandcamp.com/album/be-thyself"
                   target="_blank" rel="noopener noreferrer">
                    <img src={shopImage} alt="teespring" className="img-fluid"/>
                </a>
            </div>
        </div>
    </div>);
}

export default Shop;